import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ALERT_KINDS } from 'constants';

import Button from 'views/shared/Button';
import SvgIcon from 'views/shared/SvgIcon';
import FormattedOrRawMessage from '../FormattedOrRawMessage';

const Alert = ({
  className,
  type,
  hasNoIcon,
  message,
  isDiscardable,
  withButton,
  buttonText,
  kind,
  onDiscard,
  onClick,
}) => {
  const alertClassNames = classNames(
    className,
    'main-alert',
    {
      'main-alert--error': type === ALERT_KINDS.error,
      'main-alert--success': type === ALERT_KINDS.success,
      'main-alert--warning': type === ALERT_KINDS.warning,
      'main-alert--info': type === ALERT_KINDS.info,
    },
  );

  let icon;

  if (type === ALERT_KINDS.error || type === ALERT_KINDS.warning) {
    icon = 'alert';
  } else if (type === ALERT_KINDS.success) {
    icon = 'tick';
  } else if (type === ALERT_KINDS.info) {
    icon = 'info';
  }

  return (
    <div className={alertClassNames}>
      {!hasNoIcon && <SvgIcon icon={icon} className="main-alert__icon" /> }
      <div>
        <p className={classNames('main-alert__text', { 'mb-16': withButton })}>
          <FormattedOrRawMessage message={message} />
        </p>
        {withButton && (
          <Button
            text={buttonText}
            onClick={onClick}
            kind={kind}
            size="small"
          />
        )}
      </div>
      {isDiscardable && (
        <button className="main-alert__close" type="button" onClick={onDiscard}>
          <SvgIcon icon="cross" />
        </button>
      )}
    </div>
  );
};

Alert.defaultProps = {
  className: null,
  hasNoIcon: false,
  type: ALERT_KINDS.error,
  isDiscardable: false,
  withButton: false,
  buttonText: null,
  kind: null,
  onDiscard: null,
  onClick: null,
};

Alert.propTypes = {
  className: PropTypes.string,
  hasNoIcon: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ALERT_KINDS)),
  isDiscardable: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
  ]).isRequired,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
  ]),
  kind: PropTypes.oneOf(['flat', 'outline', 'danger', 'warning', null]),
  withButton: PropTypes.bool,
  onDiscard: PropTypes.func,
  onClick: PropTypes.func,
};

export default Alert;
